import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  publications: [
    {
      name: "La France au bout des baguettes",
      imageUrl: require("../assets/publications/la-france-au-bout-des-baguettes.jpg")
    },
    {
      name: "Grand cahier d'écriture pour tous",
      imageUrl: require("../assets/publications/grand-cahier-ecriture-pour-tous.jpg")
    },
    {
      name: "Le souhait de Hu Jie",
      imageUrl: require("../assets/publications/le-souhait-de-hu-jie.jpg")
    }
  ],
  illustrations: [
    {
      name: "Carte de voeux",
      imageUrl: require("../assets/illustrations/carte-de-voeux-monde.png")
    },
    {
      name: "Joyeux Noël",
      imageUrl: require("../assets/illustrations/joyeux-noel.jpg")
    },
    {
      name: "Chat porte-bonheur",
      imageUrl: require("../assets/illustrations/chat-porte-bonheur.jpg")
    },
    {
      name: "Saint-Valentin",
      imageUrl: require("../assets/illustrations/saint-valentin.jpg")
    },
    {
      name: "Ramen",
      imageUrl: require("../assets/illustrations/ramen.jpg")
    },
    {
      name: "Tour de Tokyo",
      imageUrl: require("../assets/illustrations/tokyo-tower.jpg")
    },
    {
      name: "Mariage",
      imageUrl: require("../assets/illustrations/wedding-1.jpg")
    },
    {
      name: "Mariage",
      imageUrl: require("../assets/illustrations/wedding-2.jpg")
    },
    {
      name: "Mariage",
      imageUrl: require("../assets/illustrations/wedding-3.jpg")
    },
    {
      name: "Xiao chi",
      imageUrl: require("../assets/illustrations/xiao-chi-1.jpg")
    },
    {
      name: "Xiao chi",
      imageUrl: require("../assets/illustrations/xiao-chi-2.jpg")
    },
    {
      name: "Xiao chi",
      imageUrl: require("../assets/illustrations/xiao-chi-3.jpg")
    },
    {
      name: "Enfants",
      imageUrl: require("../assets/illustrations/enfants-girafe.jpg")
    },
    {
      name: "Marché chinois",
      imageUrl: require("../assets/illustrations/la-france-au-bout-des-baguettes/marche-chinois.jpg")
    },
    {
      name: "Jardin chinois",
      imageUrl: require("../assets/illustrations/jardin-chinois.jpg")
    },
    {
      name: "Grande muraille",
      imageUrl: require("../assets/illustrations/la-france-au-bout-des-baguettes/grande-muraille.jpg")
    },
    {
      name: "Palais d'été",
      imageUrl: require("../assets/illustrations/la-france-au-bout-des-baguettes/palais-ete.jpg")
    },
    {
      name: "Dragon",
      imageUrl: require("../assets/illustrations/la-france-au-bout-des-baguettes/dragon.jpg")
    },
    {
      name: "Digital food",
      imageUrl: require("../assets/illustrations/digital-food.jpg")
    },
    {
      name: "Digital assistant",
      imageUrl: require("../assets/illustrations/digital-assistant.jpg")
    },
    {
      name: "Digital love",
      imageUrl: require("../assets/illustrations/digital-love.jpg")
    },
  ],
  exhibitions: [
    {
      name: "Institut Confucius",
      imageUrl: require("../assets/exhibitions/confucius.jpg")
    },
    {
      name: "Carnet de voyage",
      imageUrl: require("../assets/exhibitions/carnet-de-voyage-1.jpg")
    },
    {
      name: "Carnet de voyage",
      imageUrl: require("../assets/exhibitions/carnet-de-voyage-2.jpg")
    },
    {
      name: "Carnet de voyage",
      imageUrl: require("../assets/exhibitions/carnet-de-voyage-3.jpg")
    },
    {
      name: "Stand",
      imageUrl: require("../assets/exhibitions/stand.jpg")
    },
    {
      name: "Dédicace",
      imageUrl: require("../assets/exhibitions/dedicace.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/exhibitions/expo-1.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/exhibitions/expo-2.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/exhibitions/expo-3.jpg")
    },
  ],
  activities: [
    {
      name: "",
      imageUrl: require("../assets/activities/le-failler/le-failler-1.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/le-failler/le-failler-2.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/le-failler/le-failler-3.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/bibliotheque/bibli-1.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/bibliotheque/bibli-2.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/bibliotheque/bibli-3.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/bibliotheque/bibli-4.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/bibliotheque/bibli-5.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/bibliotheque/bibli-6.jpg")
    },
    {
      name: "",
      imageUrl: require("../assets/activities/bibliotheque/bibli-7.jpg")
    }
  ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
