<template>
  <div class="about">
    <div id="backgroundDrawing"></div>
    <div id="descriptionText" class="vertical-center">
      <p >{{ $t("about.description") }}</p>
    </div>

    <Footer/>
  </div>
</template>

<style scoped>

#backgroundDrawing {
  position: fixed;
  opacity: 0.3;
  z-index: -1;
  background-image: url('~@/assets/drawing/hu-jie.jpg');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

#descriptionText {
  position: absolute;
  margin-top: -5%;
  font-size: 1.5em;
}

@media (min-width: 600px) { 
  #descriptionText {
    margin-left: 45%;
    margin-right: 25%;   
  }
}

@media (max-width: 600px) { 
  #descriptionText {
    margin-left: 20%;
    margin-right: 10%;
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>

<script>

import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Footer,
  },
  metaInfo () {
    return {
      title: this.$t("seo.about.title"),
      meta: [
        { name: 'description', content: this.$t("seo.about.description") }
      ]
    }
  }
}
</script>
