<template>
  <div class="contact">

    <div id="contactBackgroundDrawing"></div>

    <!-- Title -->
    <h1 id="title" class="display-1 secondary--text">Contact</h1>

    <v-form @submit.prevent="submit" ref="form" lazy-validation>
      <div id="contactFormContainer">

        <!-- Name -->
        <v-text-field
          name="name"
          :label="$t('contact.form.name')"
          id="name"
          v-model="form.name"
          required
          :rules="nameRules"></v-text-field>

        <!-- Email -->
        <v-text-field
          name="email"
          :label="$t('contact.form.email')"
          id="email"
          v-model="form.email"
          required
          :rules="emailRules"></v-text-field>

        <!-- Subject -->
        <v-text-field
          name="subject"
          :label="$t('contact.form.subject')"
          id="subject"
          v-model="form.subject"
          required
          :rules="subjectRules"></v-text-field>

        <!-- Message -->
        <v-textarea
          name="message"
          :label="$t('contact.form.message')"
          id="message"
          v-model="form.message"
          multi-line
          required
          :rules="messageRules"></v-textarea>

        <!-- Human check operation -->
        <v-text-field
          id="humanCheckOp"
          name="humanCheckOp"
          :label="$t('contact.form.humanCheckOp')"
          v-model="form.humanCheckOp"
          required
          :rules="humanCheckOpRules"></v-text-field>

        <!-- Actions -->
        <v-btn class="accent mt-2 lg6" dark large
              type="submit"
              :disabled="isSending">
          {{ $t('contact.form.send') }}
        </v-btn>

        <!-- Contact details -->
        <v-list id="contactDetails">
          <!-- Phone -->
          <a href="tel:0678766878">
            <v-list-item>
              <v-icon class="contactItemIcon">mdi-phone</v-icon>
              <v-list-item-content>O6 78 76 68 78</v-list-item-content>
            </v-list-item>
          </a>
          <!-- Address -->
          <v-list-item>
            <v-icon class="contactItemIcon">mdi-map-marker</v-icon>
            <v-list-item-content>Rennes, France</v-list-item-content>
          </v-list-item>
          <!-- Email -->
          <v-list-item>
            <v-icon class="contactItemIcon">mdi-email</v-icon>
            <v-list-item-content>contact(at)xiaoyuanyuan.com</v-list-item-content>
          </v-list-item>
        </v-list>
        
      </div>
    </v-form>

    <!-- Loading -->
    <!-- TODO: not shown -->
    <v-dialog v-model="isSending" persistent max-width="400px">
      <template>

        <v-card class="pa-3">
          <v-card-text>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <span class="ml-3 mb-2 text-xs-center">{{ $t('dialog.sending_message') }}</span>
          </v-card-text>
        </v-card>

      </template>
      
      
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="showSnackbar"> {{ snackbarMessage }}</v-snackbar>

    <Footer/>
  </div>
</template>

<script>

import Footer from '@/components/Footer.vue'

export default {
  name: 'Contact',
  components: {
    Footer,
  },
  metaInfo () {
    return {
      title: this.$t("seo.contact.title"),
      meta: [
        { name: 'description', content: this.$t("seo.contact.description") }
      ]
    }
  },
  created () {
    this.$vuetify.goTo(0, { duration: 0 })
  },
  data () {
    const defaultForm = Object.freeze({
      name: '',
      email: '',
      subject: '',
      message: '',
      humanCheckOp: ''
    })

    return {
      nameRules: [
          v => !!v || this.$t('error.required'),
          v => v.length <= 50 || this.$t('error.max_x_characters', [50])
      ],
      emailRules: [
          v => {
              return !!v || this.$t('error.required')
          },
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('error.email_invalid')
      ],
      subjectRules: [
          v => !!v || this.$t('error.required'),
          v => v.length <= 100 || this.$t('error.max_x_characters', [100])
      ],
      messageRules: [
          v => !!v || this.$t('error.required'),
          v => v.length <= 2000 || this.$t('error.max_x_characters', [2000])
      ],
      humanCheckOpRules: [
          v => !!v || this.$t('error.required'),
          v => v.length <= 100 || this.$t('error.max_x_characters', [100])
      ],
      form: Object.assign({}, defaultForm),
      defaultForm,
      isSending: true,
      snackbarMessage: '',
      showSnackbar: false
    }
  },

  methods: {
    submit () {
      console.log('submit - valid form: ', this.$refs.form.validate())
      // Prevent invalid form to be sent
      if (!this.$refs.form.validate()) {
        return
      }

      // Human check operation
      if (this.form.humanCheckOp != 4) {
          return
      }

      // Create body
      let body = {
        name: this.form.name,
        email: this.form.email,
        subject: this.form.subject,
        message: this.form.message,
        humanCheckOp: this.form.humanCheckOp
      }

      // Hide send button
      this.isSending = true

      // Send email request
      this.$http.post('https://us-central1-yuanfen-illustrations.cloudfunctions.net/sendEmail', body)
        .then(response => {
          // Success callback
          this.snackbarMessage = this.$t('success.message_sent')
          this.showSnackbar = true

          this.resetForm()
        }, response => {
          // Error callback
          this.snackbarMessage = this.$t('error.message_not_sent')
          this.showSnackbar = true
        })
    },
    resetForm () {
      this.form.name = ''
      this.form.email = ''
      this.form.subject = ''
      this.form.message = ''
      this.form.humanCheckOp = ''

      // Show send button
      this.isSending = false
    }
  }
}
</script>

<style scoped>

#contactBackgroundDrawing {
  position: absolute;
  opacity: 0.3;
  z-index: -1;
  background-image: url('~@/assets/drawing/contact-background.jpg');
  background-repeat: no-repeat;
  background-position: 20%;
  width: 100%;
  height: 100%;
}

#title {
  opacity: 0;
}

#contactFormContainer {
  margin: 0 auto;
  margin-top: 24px;
  max-width: 500px;
  padding: 24px;
}

@media (min-width: 840px) { 
  #contactFormContainer {
    margin-left: 20%;
  }
}

#contactDetails {
  margin-top: 16px;
}

#contactDetails a:link {
  text-decoration: none;
}

.contactItemIcon {
  margin-right: 16px;
}

</style>