<template>
  <div class="home">

    <div id="backgroundDrawing"></div>

    <!-- Publications -->
    <h1 class="title">{{ $t("home.publications.title") }}</h1>
    
    <vue-horizontal-list
      class="horizontalList"
      :items="publications"
      :options="{
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 1 },
          { size: 3 },
        ],
      }"
    >
      <template v-slot:default="{item}">
        <div >
          <v-img class="galleryItemImg" :src="item.imageUrl" contain aspect-ratio="1"></v-img>
          <h4 class="galleryItemTitle">{{item.name}}</h4>
        </div>
      </template>
    </vue-horizontal-list>

    <!-- Illustrations -->
    <h1 class="title">{{ $t("home.illustrations.title") }}</h1>

    <vue-horizontal-list
      class="horizontalList"
      :items="illustrations"
      :options="{
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 1 },
          { size: 3 },
        ],
      }"
    >
      <template v-slot:default="{item}">
        <div>
          <v-img class="galleryItemImg" :src="item.imageUrl" contain aspect-ratio="1.78"></v-img>
          <h4 class="galleryItemTitle">{{item.name}}</h4>
        </div>
      </template>
    </vue-horizontal-list>

    <!-- Exhibitions -->
    <h1 class="title">{{ $t("home.exhibitions.title") }}</h1>

     <vue-horizontal-list
      class="horizontalList"
      :items="exhibitions"
      :options="{
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 1 },
          { size: 3 },
        ],
      }"
    >
      <template v-slot:default="{item}">
        <div>
          <v-img class="galleryItemImg" :src="item.imageUrl" contain aspect-ratio="1.78"></v-img>
          <h4 class="galleryItemTitle">{{item.name}}</h4>
        </div>
      </template>
    </vue-horizontal-list>

    <!-- Activities -->
    <h1 class="title">{{ $t("home.activities.title") }}</h1>

     <vue-horizontal-list
      class="horizontalList"
      :items="activities"
      :options="{
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 1 },
          { size: 3 },
        ],
      }"
    >
      <template v-slot:default="{item}">
        <div>
          <v-img class="galleryItemImg" :src="item.imageUrl" contain aspect-ratio="1.78"></v-img>
          <h4 class="galleryItemTitle">{{item.name}}</h4>
        </div>
      </template>
    </vue-horizontal-list>

    <Footer/>

  </div>
</template>

<style scoped>

.home {
  padding-top: 40px;
  padding-bottom: 80px;
}

#backgroundDrawing {
  position: fixed;
  opacity: 0.3;
  top: 0;
  z-index: -1;
  background-image: url('~@/assets/drawing/home-background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  width: 120%;
  height: 140%;
  transform: translate(calc(-3% * var(--mouseX) - 10%), calc(-3% * var(--mouseY) - 13%));
  transition: transform 0.2s
}

.horizontalList {
  margin-left: 36px;
  margin-right: 36px;
}

.imgContainer {
  overflow-x: auto;
  white-space: nowrap;
}

.carousel {
  width: 70%;
  margin: 0 auto;
  margin-top: 20px;
}

.galleryItemImg {
  max-width: 600px;
  margin: 0 auto;
}

.galleryItemTitle {
  margin-top: 16px;
  visibility: hidden;
}

.title {
  margin-top: 80px;
  text-align: center;
}

</style>

<script>

import Footer from '@/components/Footer.vue'
import VueHorizontalList from "vue-horizontal-list"

export default {
  name: 'Home',
  components: {
    Footer,
    VueHorizontalList,
  },
  metaInfo () {
    return {
      title: this.$t("seo.home.title"),
      meta: [
        { name: 'description', content: this.$t("seo.home.description") }
      ]
    }
  },
  mounted() {
  },
  computed: {
    publications() {
      return this.$store.state.publications
    },
    illustrations() {
      return this.$store.state.illustrations
    },
    exhibitions() {
      return this.$store.state.exhibitions
    },
    activities() {
      return this.$store.state.activities
    }
  },
  data() {
    return {
    }
  }
}
</script>
