import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/:lang',
    name: 'Home',
    component: Home,
    beforeEnter (to, from, next) {
      const lang = to.params.lang
      const langExists = Object.prototype.hasOwnProperty.call(i18n.messages, lang)

      // Redirect to root domain in case of default locale
      if (lang === i18n.fallbackLocale) {
        return next('/')
      }
      if (!langExists) {
        i18n.locale = i18n.fallbackLocale
        return next('/')
      }
      if (i18n.locale !== lang && langExists) {
        i18n.locale = lang
        return next('/')
      }
    }
  },
  {
    path: '/:lang/about',
    name: 'About',
    component: About,
    beforeEnter (to, from, next) {
      const lang = to.params.lang
      const langExists = Object.prototype.hasOwnProperty.call(i18n.messages, lang)

      // Redirect to root domain in case of default locale
      if (lang === i18n.fallbackLocale) {
        return next('/about')
      }
      if (!langExists) {
        i18n.locale = i18n.fallbackLocale
        return next('/about')
      }
      if (i18n.locale !== lang && langExists) {
        i18n.locale = lang
        return next('/about')
      }
    }
  },
  {
    path: '/:lang/contact',
    name: 'Contact',
    component: About,
    beforeEnter (to, from, next) {
      const lang = to.params.lang
      const langExists = Object.prototype.hasOwnProperty.call(i18n.messages, lang)

      // Redirect to root domain in case of default locale
      if (lang === i18n.fallbackLocale) {
        return next('/contact')
      }
      if (!langExists) {
        i18n.locale = i18n.fallbackLocale
        return next('/contact')
      }
      if (i18n.locale !== lang && langExists) {
        i18n.locale = lang
        return next('/contact')
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
