var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{attrs:{"id":"backgroundDrawing"}}),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("home.publications.title")))]),_c('vue-horizontal-list',{staticClass:"horizontalList",attrs:{"items":_vm.publications,"options":{
      responsive: [
        { end: 576, size: 1 },
        { start: 576, end: 768, size: 1 },
        { size: 3 } ],
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('v-img',{staticClass:"galleryItemImg",attrs:{"src":item.imageUrl,"contain":"","aspect-ratio":"1"}}),_c('h4',{staticClass:"galleryItemTitle"},[_vm._v(_vm._s(item.name))])],1)]}}])}),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("home.illustrations.title")))]),_c('vue-horizontal-list',{staticClass:"horizontalList",attrs:{"items":_vm.illustrations,"options":{
      responsive: [
        { end: 576, size: 1 },
        { start: 576, end: 768, size: 1 },
        { size: 3 } ],
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('v-img',{staticClass:"galleryItemImg",attrs:{"src":item.imageUrl,"contain":"","aspect-ratio":"1.78"}}),_c('h4',{staticClass:"galleryItemTitle"},[_vm._v(_vm._s(item.name))])],1)]}}])}),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("home.exhibitions.title")))]),_c('vue-horizontal-list',{staticClass:"horizontalList",attrs:{"items":_vm.exhibitions,"options":{
      responsive: [
        { end: 576, size: 1 },
        { start: 576, end: 768, size: 1 },
        { size: 3 } ],
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('v-img',{staticClass:"galleryItemImg",attrs:{"src":item.imageUrl,"contain":"","aspect-ratio":"1.78"}}),_c('h4',{staticClass:"galleryItemTitle"},[_vm._v(_vm._s(item.name))])],1)]}}])}),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("home.activities.title")))]),_c('vue-horizontal-list',{staticClass:"horizontalList",attrs:{"items":_vm.activities,"options":{
      responsive: [
        { end: 576, size: 1 },
        { start: 576, end: 768, size: 1 },
        { size: 3 } ],
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('v-img',{staticClass:"galleryItemImg",attrs:{"src":item.imageUrl,"contain":"","aspect-ratio":"1.78"}}),_c('h4',{staticClass:"galleryItemTitle"},[_vm._v(_vm._s(item.name))])],1)]}}])}),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }