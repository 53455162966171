<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">{{ $t("nav.home") }}</router-link> |
      <router-link to="/about">{{ $t("nav.about") }}</router-link> |
      <router-link to="/contact">{{ $t("nav.contact") }}</router-link>
    </div>
    <router-view/>
  </div>
</template>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap');

#app {
  font-family: 'Calligraffitti', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  padding: 30px;
  z-index: 1;
  font-size: 1.5em;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;

    &.router-link-exact-active {
      color: #b96842;
    }
  }
}
</style>

<script>

const pos = { x : 0, y : 0 };

const saveCursorPosition = function(x, y) {
    pos.x = (x / window.innerWidth).toFixed(2);
    pos.y = (y / window.innerHeight).toFixed(2);
    document.documentElement.style.setProperty('--mouseX', pos.x);
    document.documentElement.style.setProperty('--mouseY', pos.y);
}

document.addEventListener('mousemove', e => { saveCursorPosition(e.clientX, e.clientY); })
saveCursorPosition(0, 0)

export default {
  mounted() {
    // const navigatorLanguage = this.getNavigatorLanguage().split("-")[0]
    // this.setLocale(navigatorLanguage)
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
    },
    getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
    }
  }
}
</script>